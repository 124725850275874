<template>
  <div class="row">
    <div class="ten wide column">
      <h1>{{ flatpage.title }}</h1>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="flatpage.content" />
      <!--eslint-enable-->
      <div class="ui right rail">
        <div
          id="toc-container"
          class="ui sticky fixed"
        >
          <h4 class="ui header">
            Table des matières
          </h4>
          <div
            id="page-toc"
            class="ui vertical large text menu"
          >
            <a
              v-for="h2 in sections"
              :key="h2.id"
              class="item"
              :href="'#' + h2.id"
            >{{ h2.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Mentions',

  data() {
    return {
      sections: [],
    };
  },

  computed: {
    ...mapState(['staticPages']),
    flatpage() {
      if (this.staticPages) {
        return this.staticPages.find(
          (page) => page.url === `/${this.$route.name}/`
        );
      }
      return null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // The whole view is rendered, so we can safely access or query the DOM.
      this.createMenu();
    });
  },

  methods: {
    createMenu() {
      // parse the ToC content (looking for h2 elements)
      const list = document.querySelectorAll('h2');
      const tocArr = [];

      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        let id = e.id;

        // add id in html if not present
        if (id === '') {
          id = 'toc-id-' + i;
          e.id = id;
        }

        tocArr.push({
          text: e.textContent,
          id: id,
        });
      }
      this.sections = tocArr;
    },
  },
};
</script>
